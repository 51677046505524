<template>
  <v-container fluid class="text-center">
    <v-row>
      <v-col cols="12">
        <v-card class="elevation-5 mt-5 mx-5">
          <v-toolbar dense dark color="secondary" elevation="2">
            <v-toolbar-title>
              Importación masiva
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn text small @click="dialogs.import = true">
              Agregar
              <v-icon right>mdi-plus</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-title>
            <v-btn small :loading="loading" elevation="0" color="secondary" outlined class="mx-2" @click="refresh()">
              Actualizar
              <v-icon right>mdi-refresh</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" class="mx-2" single-line hide-details maxlength="150"></v-text-field>
          </v-card-title>
          <v-data-table v-model="selected" :headers="headers" :items="items" :loading="loading" :search="search"
          :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [20, 50, -1] }" :items-per-page="10" item-key="id" class="elevation-1" dense>
            <v-progress-linear slot="progress" color="secondary" indeterminate></v-progress-linear>
            <template v-slot:[`item.tipo`]="{ item }">
              {{ item.tipo.replace(/\_/, ' ').toUpperCase() }}
            </template>
            <template v-slot:[`item.fecha_creacion`]="{ item }">
              {{ formatDate(item.fecha_creacion) }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="primary" dark v-bind="attrs" v-on="on" :disabled="loading" @click="downloadValues(item)">mdi-download</v-icon></template><span>Descargar valores</span></v-tooltip>
            </template>
            <template v-slot:[`footer.page-text`]="props">
              Página {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
            </template>
            <template v-slot:no-data>
              No hay información disponible
            </template>
            <template v-slot:no-results>
              No se obtuvieron resultados
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogs.import" persistent scrollable max-width="800px">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Importar archivo</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn v-if="!forms.import.data.items.length" text small :loading="loading" @click="acceptImport()">
            Cargar
            <v-icon right>mdi-arrow-up</v-icon>
          </v-btn>
          <v-btn v-else-if="forms.import.data.tipo && forms.import.data.items.length" text small :loading="loading" @click="saveImport()">
            Guardar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form v-show="!forms.import.data.items.length" ref="formImport" lazy-validation>
              <v-row>
                <v-col cols="6">
                  <v-autocomplete v-model="forms.import.data.tipo" :items="catalogues.tipos" label="Tipo" :disabled="loading" required></v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-file-input v-model="forms.import.id" chips small-chips show-size label="Seleccionar archivo..." :accept="catalogues.mime_types" :disabled="loading"></v-file-input>
                </v-col>
              </v-row>
            </v-form>
            <v-data-table v-show="forms.import.data.items.length" :headers="forms.import.data.headers" :items="forms.import.data.items"
            :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [10, 20, -1] }" :items-per-page="10">
              <template v-slot:[`footer.page-text`]="props">
                Página {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
              </template>
              <template v-slot:no-data> No hay información disponible </template>
              <template v-slot:no-results> No se obtuvieron resultados </template>
            </v-data-table>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="success" color="success" :timeout="2500" bottom right>
      {{ msgSuccess }}
    </v-snackbar>
    <v-snackbar v-model="error" color="error" :timeout="2500" bottom right>
      {{ msgError }}
    </v-snackbar>
  </v-container>
</template>

<script>
import services from '@/utils/services'
import * as XLSX from "xlsx"
import * as Papa from "papaparse"
import writeXlsxFile from 'write-excel-file'
import moment from 'moment-timezone'
import _ from 'lodash'

export default {
  name: 'massiveImport',
  data: () => ({
    loading: false,
    success: false,
    msgSuccess: '',
    error: false,
    msgError: '',
    search: '',
    headers: [
      { text: 'Tipo de importación', align: 'left', sortable: false, value: 'tipo' },
      { text: 'Cantidad de registros', align: 'left', sortable: false, value: 'cantidad_registros' },
      { text: 'Usuario', align: 'left', sortable: false, value: 'usuario_creacion[0].email' },
      { text: 'Fecha', align: 'left', sortable: false, value: 'fecha_creacion' },
      { text: 'Acciones', align: 'left', sortable: false, value: 'actions' }
    ],
    items: [],
    selected: [],
    catalogues: {
      tipos: [
        { text: 'Empresa', value: 'empresa' },
        { text: 'Detalle de empresa', value: 'detalle_empresa' },
        { text: 'Registro', value: 'registro' }
      ],
      mime_types: [
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ]
    },
    forms: {
      import: {
        id: null,
        data: {
          tipo: '',
          headers: [],
          items: []
        }
      }
    },
    dialogs: {
      import: false
    }
  }),
  mounted() {
    this.refresh()
  },
  methods: {
    async refresh() {
      this.loading = true
      this.axios.get(services.routes.import + '/log')
        .then(response => {
          this.items = response.data.data
        })
        .catch(error => {})
        .finally(() => {
          this.loading = false
        })
    },
    cancel() {
      if (this.dialogs.import) {
        this.$refs.formImport.reset()
        this.forms.import = {
          id: null,
          data: {
            tipo: '',
            headers: [],
            items: []
          }
        }
        this.dialogs.import = false
      }
    },
    loadValues(data) {
      if (data.length > 1) {
        let headers = []
        let values = []
        let keys = {}
        for (let i = 0; i < data[0].length; i++) {
          let text = data[0][i].toString()
          let value = data[0][i].toString().toLowerCase()
          if (!_.has(keys, value)) {
            keys[value] = 0
          }
          else {
            keys[value] += 1
            text = text + '_' + keys[value]
            value = value + '_' + keys[value]
          }
          headers.push(value)
          this.forms.import.data.headers.push({
            text: text, align: "left", sortable: false, value: value
          })
        }
        for (let i = 1; i < data.length; i++) {
          let row = {};
          for (let j = 0; j < data[i].length; j++) {
            row[headers[j]] = data[i][j];
          }
          values.push(row);
        }
        this.forms.import.data.items = values;
      } else {
        this.error = true;
        this.msgError = "El formato del archivo es inválido";
      }
    },
    acceptImport() {
      if (this.forms.import.id) {
        let fr = new FileReader();
        let file = this.forms.import.id;
        let size = this.forms.import.id.size;
        if (size <= 3000000) {
          this.loading = true;
          setTimeout(() => {
            fr.onload = () => {
              let data = XLSX.read(fr.result, { type: "array" });
              let sheetNames = data.SheetNames;
              let worksheet = data.Sheets[sheetNames[0]];
              let csv = XLSX.utils.sheet_to_csv(worksheet).trim();
              let values = Papa.parse(csv);
              if (values.data && values.data.length) {
                values = values.data;
                this.loadValues(values);
              } else {
                this.error = true;
                this.msgError = "El archivo no fue leído correctamente";
              }
              this.loading = false;
            };
            fr.onerror = () => {
              this.loading = false;
              this.forms.import.id = null;
              this.error = true;
              this.msgError = "El archivo no fue leído correctamente";
            };
            fr.readAsArrayBuffer(file);
          }, 500);
        } else {
          this.error = true;
          this.msgError = "El archivo ha excedido el límite permitido (3 MB)";
        }
      } else {
        this.error = true;
        this.msgError = "Seleccionar archivo";
      }
    },
    saveImport() {
      if (this.$refs.formImport.validate()) {
        this.loading = true
        let data = JSON.parse(JSON.stringify(this.forms.import.data))
        this.axios.post(services.routes.import, { data })
          .then(response => {
            this.cancel()
            this.refresh()
            this.success = true
            this.msgSuccess = 'Información importada exitosamente'
          })
          .catch(error => {
            this.error = true
            this.msgError = error.response.data.message || 'Servicio no disponible'
          })
          .finally(() => {
            this.loading = false
          })
      }
      else {
        this.error = true
        this.msgError = 'La información es incorrecta'
      }
    },
    downloadValues(data) {
      this.loading = true
      let params = { id: data.idlog_importacion }
      this.axios.get(services.routes.import + '/log/value', { params })
        .then(response => {
          if (response.data.data.columnas.length && response.data.data.valores.length) {
            let _data = []
            let headers = []
            for (let i = 0; i < response.data.data.columnas.length; i++) {
              let key = response.data.data.columnas[i].text
              headers.push({
                value: key,
                fontWeight: 'bold'
              })
            }
            _data.push(headers)
            for (let i = 0; i < response.data.data.valores.length; i++) {
              let rows = []
              for (let j = 0; j < response.data.data.columnas.length; j++) {
                let key = response.data.data.columnas[j].value
                rows.push({
                  type: String,
                  value: response.data.data.valores[i][key]
                })
              }
              _data.push(rows)
            }
            let fileName = data.tipo + ' ' + moment.tz(data.fecha_creacion, 'UTC').tz('America/Monterrey').format('YYYY-MM-DD HH:mm') + '.xlsx'
            writeXlsxFile(_data, { fileName })
            this.loading = false
          }
          else {
            this.error = true
            this.msgError = 'No hay información disponible'
          }
        })
        .catch(error => {})
        .finally(() => {
          this.loading = false
        })
    },
    formatDate(date) {
      return moment.tz(date, 'UTC').tz('America/Monterrey').format('YYYY-MM-DD HH:mm')
    }
  }
}
</script>

<style scoped>
</style>
